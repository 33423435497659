import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  useColorMode,
  Wrap,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import RouterLink from './Utility/Routing/RouterLink';
import ImgTextModule from './elements/ImgTextModule';
import FadeInAnimation from './UX/FadeInAnimation';
import MagicText from './UX/MagicText';
import ImgMaskRevealParallax from './UX/ImgMaskRevealParallax';
function ExpertenQuint() {
  return (
    <Container variant='layoutContainer' my={16} maxW={1160}>
      <Container variant='layoutContainer' mt={8}>
        <Box
          p={{
            base: 4,
            lg: '8',
          }}
        >
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.4}
            initialX={0}
            initialY={-10}
          >
            <Text
              color='brand.gray.300'
              textAlign='center'
              fontWeight='normal'
              fontSize={{
                base: 'md',
                lg: 'lg',
              }}
            >
              Wir arbeiten gemeinsam an Ihrem Erfolg.
            </Text>
            <Text
              textAlign='center'
              color='brand.yellow'
              fontWeight='normal'
              fontSize={{
                base: '4xl',
                lg: '6xl',
              }}
            >
              Unsere Experten
            </Text>
          </FadeInAnimation>
        </Box>
      </Container>
      <Flex
        gap={0}
        flexWrap={'wrap'}
        direction={{
          base: 'column',
          md: 'row',
        }}
        justifyContent={'center'}
      >
        <Flex
          className='expertenquintCard'
          w={{ base: '100%', md: '50%', lg: '32%' }}
          direction={{
            base: 'column',
            md: 'column',
          }}
          p={4}
        >
          <Box
            bg={'brand.gray.1000'}
            h={'100%'}
            borderRadius={8}
            boxShadow='2xl'
            overflow={'hidden'}
          >
            <ImgMaskRevealParallax
              imgsrc={'ingo.jpg'}
              initScale={0.9}
              maxH={400}
              duration={1.5}
              delay={0}
              maxW={{
                base: '100%',
                md: '50vw',
              }}
            />

            <Box
              w={{
                base: '100%',
                md: '100%',
              }}
            >
              <Box p={4}>
                <FadeInAnimation
                  delay={0.5}
                  duration={0.5}
                  threshold={0}
                  initialX={0}
                  initialY={0}
                >
                  <Heading
                    size={{ base: 'md', md: 'md' }}
                    lineHeight={'base'}
                    color={'brand.blue'}
                    fontWeight={'bold'}
                  >
                    Ingo Dykstra
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Rechtsanwalt/ Therapeut
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Lehrbeauftragter für Psychologie im Fachbereich MIT
                    Management/ Information/ Technologie:
                    <br />
                    Medienwirtschaft & Journalismus
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Coaching
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Kommunikation
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Konflikte
                  </Heading>
                </FadeInAnimation>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Flex
          className='expertenquintCard'
          w={{ base: '100%', md: '50%', lg: '32%' }}
          direction={{
            base: 'column',
            md: 'column',
          }}
          p={4}
        >
          <Box
            bg={'brand.gray.1000'}
            borderRadius={8}
            h={'100%'}
            boxShadow='2xl'
            overflow={'hidden'}
          >
            <ImgMaskRevealParallax
              imgsrc={'olli.jpg'}
              initScale={0.9}
              maxH={400}
              duration={1.5}
              delay={0}
              maxW={{
                base: '100%',
                md: '50vw',
              }}
            />

            <Box
              w={{
                base: '100%',
                md: '100%',
              }}
            >
              <Box p={4}>
                <FadeInAnimation
                  delay={0.5}
                  duration={0.5}
                  threshold={0}
                  initialX={0}
                  initialY={0}
                >
                  <Heading
                    size={{ base: 'md', md: 'md' }}
                    lineHeight={'base'}
                    color={'brand.blue'}
                    fontWeight={'bold'}
                  >
                    Dr. Oliver Lange
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Individuelles Executive Coaching, C-Level
                    <br />
                    Schwerpunkte: Wirksamkeit & Strategie
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Team-Coaching <br />
                    Schwerpunkte: Veränderungen führen & Teamentwicklung
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    HR-Strategieberatung <br />
                    Schwerpunkte: nachhaltiger Personalumbau (workforce
                    transformation) & Organisationsentwicklung
                  </Heading>
                </FadeInAnimation>
              </Box>
            </Box>
          </Box>
        </Flex>

        <Flex
          className='expertenquintCard Paula'
          w={{ base: '100%', md: '50%', lg: '32%' }}
          direction={{
            base: 'column',
            md: 'column',
          }}
          p={4}
        >
          <Box
            bg={'brand.gray.1000'}
            borderRadius={8}
            h={'100%'}
            boxShadow='2xl'
            overflow={'hidden'}
          >
            <ImgMaskRevealParallax
              imgsrc={'team/paula-6312.jpg'}
              initScale={0.9}
              maxH={400}
              duration={1.5}
              delay={0}
              maxW={{
                base: '100%',
                md: '50vw',
              }}
            />

            <Box
              w={{
                base: '100%',
                md: '100%',
              }}
            >
              <Box p={4}>
                <FadeInAnimation
                  delay={0.5}
                  duration={0.5}
                  threshold={0}
                  initialX={0}
                  initialY={0}
                >
                  <Heading
                    size={{ base: 'md', md: 'md' }}
                    lineHeight={'base'}
                    color={'brand.blue'}
                    fontWeight={'bold'}
                  >
                    Paula Steinert
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Expertin für Vergabeverfahren mit dem Schwerpunkt: Facility
                    Management im Health-Care Bereich
                  </Heading>

                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Beratung Fördermittelmanagement im Health-Care Bereich
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Organisationsberatung Krankenhaus / Facility Management{' '}
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Projektleiterin / Beratung bei öffentlichen Ausschreibungen
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Fachkraft für Arbeitssicherheit
                  </Heading>
                </FadeInAnimation>
              </Box>
            </Box>
          </Box>
        </Flex>

        <Flex
          className='expertenquintCard'
          w={{ base: '100%', md: '50%', lg: '32%' }}
          direction={{
            base: 'column',
            md: 'column',
          }}
          p={4}
        >
          <Box
            h={'100%'}
            bg={'brand.gray.1000'}
            borderRadius={8}
            boxShadow='2xl'
            overflow={'hidden'}
          >
            <ImgMaskRevealParallax
              imgsrc={'martin.jpg'}
              initScale={0.9}
              maxH={400}
              duration={1.5}
              delay={0}
              maxW={{
                base: '100%',
                md: '50vw',
              }}
            />

            <Box
              w={{
                base: '100%',
                md: '100%',
              }}
            >
              <Box p={4}>
                <FadeInAnimation
                  delay={0.5}
                  duration={0.5}
                  threshold={0}
                  initialX={0}
                  initialY={0}
                >
                  <Heading
                    size={{ base: 'md', md: 'md' }}
                    lineHeight={'base'}
                    color={'brand.blue'}
                    fontWeight={'bold'}
                  >
                    Martin Adelstein
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Designer / Managing Partner Creative
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Digital Brand Strategy
                    <br />
                    Design, Ideation und Development von digitalen Produkten
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Content Strategy & Creation
                  </Heading>

                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Managing Partner der definition.group mit Sitz in
                    Wilhelmshaven und Las Palmas
                  </Heading>
                </FadeInAnimation>
              </Box>
            </Box>
          </Box>
        </Flex>

        <Flex
          className='expertenquintCard'
          w={{ base: '100%', md: '50%', lg: '32%' }}
          direction={{
            base: 'column',
            md: 'column',
          }}
          p={4}
        >
          <Box
            bg={'brand.gray.1000'}
            borderRadius={8}
            h={'100%'}
            boxShadow='2xl'
            overflow={'hidden'}
          >
            <ImgMaskRevealParallax
              imgsrc={'kevin.jpg'}
              initScale={1}
              maxH={400}
              duration={1.5}
              delay={0}
              maxW={{
                base: '100%',
                md: '50vw',
              }}
            />

            <Box
              w={{
                base: '100%',
                md: '100%',
              }}
            >
              <Box p={4}>
                <FadeInAnimation
                  delay={0.5}
                  duration={0.5}
                  threshold={0}
                  initialX={0}
                  initialY={0}
                >
                  <Heading
                    size={{ base: 'md', md: 'md' }}
                    lineHeight={'base'}
                    color={'brand.blue'}
                    fontWeight={'bold'}
                  >
                    Kevin Aniol
                  </Heading>

                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Social-Media Strategieentwicklung und Umsetzung
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Videograf für Kurzvideos
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Foto- und Design von Digitalen und Analogen Produkten
                  </Heading>
                  <Heading
                    display={'flex'}
                    size={{ base: 'xs', md: 'xs', lg: 'xs' }}
                    lineHeight={'base'}
                    color={'brand.gray.500'}
                    py={1}
                    my={1}
                  >
                    <Text ml={{ base: 4, md: 4 }} mr={{ base: 4, md: 4 }}>
                      ·
                    </Text>
                    Geschäftsführer der Simple Hanse Marketing Agentur mit Sitz
                    in Reinfeld
                  </Heading>
                </FadeInAnimation>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
}

export default ExpertenQuint;
